<template>
  <div>
    <h1>Skapa roll</h1>
    <hr />
    <b-form @submit.prevent="onSubmit">
      <b-form-group id="input-group-name" label="Namn" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          type="text"
          placeholder="Ange namn"
          :disabled="isSubmitting"
          autocomplete="off"
          aria-describedby="input-name-help-block"
          required
        />
        <b-form-text id="input-name-help-block">
          <ul class="text-danger mb-0">
            <li v-for="message in validationByKey('Name')" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-form-text>
      </b-form-group>

      <b-alert :show="validationByKey('Other').length > 0" variant="danger">
        <ul class="text-danger mb-0">
          <li v-for="message in validationByKey('Other')" :key="message">
            {{ message }}
          </li>
        </ul>
      </b-alert>

      <b-button
        v-b-tooltip.hover
        variant="primary"
        class="float-right"
        :disabled="isSubmitting"
        type="submit"
        :title="isSubmitting ? 'Skapar...' : ''"
      >
        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Skapa
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { post } from '@/helpers/api';

export default {
  name: 'SaveRole',
  data() {
    return {
      isSubmitting: false,
      roleId: null,
      form: {
        name: ''
      },
      validationErrors: null
    };
  },
  methods: {
    async onSubmit() {
      // Resets.
      this.validationErrors = null;
      this.isSubmitting = true;

      // Post.
      await post('Role', 'Create', this.form)
        .then((x) => {
          this.roleId = x.data.entityId;
          this.validationErrors = x.data.errors;
        })
        .catch((x) => {
          // Map errors.
          this.validationErrors = x.response.data.errors;
        });
      this.isSubmitting = false;

      // On created.
      if (!this.validationErrors && this.roleId) this.onCreated();
    },
    onCreated() {
      this.$router.push({ name: 'admin-manage-role-list' });
    },
    validationByKey(code) {
      return this.validationErrors ? this.validationErrors[code] || [] : [];
    },
    resetForm() {
      this.form = {
        name: ''
      };
      this.validationErrors = null;
    }
  }
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
</style>
